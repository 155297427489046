<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="SMS_AD_ONS"
      tooltip-title="SMS_AD_ONS"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div v-if="isLoading" class="mt-28">
      <Loader :content="true" />
    </div>
    <div
      v-else
      class="bg-white min-h-screen md:py-7.5 py-3 md:px-7.5 px-3 mt-5 border border-primary-grey rounded-lg font-roboto font-medium text-text-color text-xs sm:text-sm md:text-base"
    >
      <div>
        <!-- Provider Type and api key -->
        <div class="flex flex-col gap-3 md:gap-5">
          <div class="flex items-center">
            <div class="min-w-1 sm:w-1/4 md:w-2/4">
              <span>Title</span>
            </div>
            <div class="text-primary-grey-light capitalize">
              {{ adapterDetails && adapterDetails.title }}
            </div>
          </div>
          <div class="flex items-center">
            <div class="min-w-1 sm:w-1/4 md:w-2/4">
              <span>URL</span>
            </div>
            <div class="text-primary-grey-light">
              {{ adapterDetails.url }}
            </div>
          </div>
          <div class="flex items-center">
            <div class="min-w-1 sm:w-1/4 md:w-2/4">
              <span>TO</span>
            </div>
            <div class="text-primary-grey-light">
              {{ adapterDetails.to_key }}
            </div>
          </div>
          <div class="flex items-center">
            <div class="min-w-1 sm:w-1/4 md:w-2/4">
              <span>Message</span>
            </div>
            <div class="text-primary-grey-light">
              {{ adapterDetails.message_key }}
            </div>
          </div>
        </div>
        <div v-if="isAdapterHeaderAvailable">
          <Divider />
          <div class="flex gap-5 mt-5 md:mt-7.5 flex-col">
            <div class="min-w-1 sm:w-1/4 md:w-2/4">Headers Keys</div>
            <div
              v-for="(value, key, index) in adapterDetails.headers"
              :key="index"
              class="flex items-center text-primary-grey-light"
            >
              <div class="min-w-1 sm:w-1/4 md:w-2/4">
                <span>{{ transformTextToCapitalize(key) }}</span>
              </div>
              <div class="p-1 rounded">{{ value }}</div>
            </div>
          </div>
        </div>
        <div v-if="isAdapterParamsAvailable">
          <Divider />
          <div class="flex gap-5 mt-5 md:mt-7.5 flex-col">
            <div class="min-w-1 sm:w-1/4 md:w-2/4">Params Keys</div>
            <div
              v-for="(value, key, index) in adapterDetails.params"
              :key="index"
              class="flex items-center text-primary-grey-light"
            >
              <div class="min-w-1 sm:w-1/4 md:w-2/4">
                <span>{{ transformTextToCapitalize(key) }}</span>
              </div>
              <div class="p-1 rounded">{{ value }}</div>
            </div>
          </div>
        </div>
        <div v-if="isAdapterHeadersSecrectsAvailable">
          <Divider />
          <div class="flex gap-5 mt-5 md:mt-7.5 flex-col">
            <div class="min-w-1 sm:w-1/4 md:w-2/4">Secret Headers Keys</div>
            <div
              v-for="(value, key, index) in adapterDetails.headers_secrets"
              :key="index"
              class="flex items-center text-primary-grey-light"
            >
              <div class="min-w-1 sm:w-1/4 md:w-2/4">
                <span>{{ transformTextToCapitalize(key) }}</span>
              </div>
              <div class="p-1 rounded">{{ value }}</div>
            </div>
          </div>
        </div>
        <div v-if="isAdapterParamsSecrectsAvailable">
          <Divider />
          <div class="flex gap-5 mt-5 md:mt-7.5 flex-col">
            <div class="min-w-1 sm:w-1/4 md:w-2/4">Secret Params Keys</div>
            <div
              v-for="(value, key, index) in adapterDetails.params_secrets"
              :key="index"
              class="flex items-center text-primary-grey-light"
            >
              <div class="min-w-1 sm:w-1/4 md:w-2/4">
                <span>{{ transformTextToCapitalize(key) }}</span>
              </div>
              <div class="p-1 rounded">{{ value }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@components/BaseComponent/Loader.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions } from 'vuex'
import { transformTextToCapitalize, getCountFromObject } from '@utils/generalUtil.js'
import Divider from '@src/components/Divider.vue'

export default {
  components: {
    Loader,
    TitleWrapper,
    Divider,
  },
  data() {
    return {
      adapterDetails: {},
      isLoading: false,
    }
  },
  computed: {
    currentAdapterId() {
      return this.$route?.params?.id || ''
    },
    isAdapterHeaderAvailable() {
      return getCountFromObject(this.adapterDetails.headers)
    },
    isAdapterParamsAvailable() {
      return getCountFromObject(this.adapterDetails.params)
    },
    isAdapterHeadersSecrectsAvailable() {
      return getCountFromObject(this.adapterDetails.headers_secrets)
    },
    isAdapterParamsSecrectsAvailable() {
      return getCountFromObject(this.adapterDetails.params_secrets)
    },
  },
  mounted() {
    this.getProviderDetails()
  },
  methods: {
    transformTextToCapitalize,
    getCountFromObject,
    getProviderDetails() {
      this.isLoading = true
      let id = this.currentAdapterId
      this.getSMSProviderDetails(id).then((res) => {
        this.adapterDetails = res?.data
        this.isLoading = false
      })
    },
    ...mapActions('addons', ['getSMSProviderDetails']),
  },
}
</script>
